<template>
  <div class="modalContainer">
    <div v-if="restaurantData" v-click-outside="closeInfoModal" class="modal">
      <div class="orderButton">
        <div class="button">
          <button @click="openLocation(restaurantData.restaurant_code)">ORDER</button>
        </div>
      </div>
      <div class="map">
        <a class="close" @click="closeInfoModal" href="#">
          <img src="../assets/close.svg" alt="">
        </a>
        <GMapMap
          :center="center"
          :zoom="14"
          :options="options"
          map-type-id="terrain"
          class="mapPart"
          v-if="center"
        >
          <GMapMarker
            :position="center"
            :icon="icon"
            :clickable="true"
            @click="center= { lat: restaurantData.lat, lng: restaurantData.lng }"
          />
        </GMapMap>
      </div>
      <div class="content">
        <div class="info">
          <h1>{{ restaurantData.restaurant_name }}</h1>
          <p>{{ restaurantAddress }}</p>
          <p>{{ restaurantData.phone }}</p>
        </div>
        <div class="orderTypes">
          <h3>Order Types</h3>
          <p>{{ orderTypesList }}</p>
        </div>
        <div v-if="restaurantData.delivery_type === 1" class="hours">
          <h3>Delivery Hours</h3>
          <div v-for="day in deliveryHours" :key="day.day" class="line" :class="{ bold: currentDate === day.short }">
            <p>{{ day.day }}</p>
            <div>
              <p>{{ day.time && day.time.length > 0 ? day.time : 'CLOSED' }}</p>
            </div>
          </div>
        </div>
        <div v-if="restaurantData.pickup_type === 1" class="hours">
          <h3>Pickup Hours</h3>
          <div v-for="day in pickupHours" :key="day.day" class="line" :class="{ bold: currentDate === day.short }">
            <p>{{ day.day }}</p>
            <div>
              <p>{{ day.time && day.time.length > 0 ? day.time : 'CLOSED' }}</p>
            </div>
          </div>
        </div>
        <div v-if="restaurantData.curbside_type === 1" class="hours">
          <h3>Curbside Hours</h3>
          <div v-for="day in curbsideHours" :key="day.day" class="line" :class="{ bold: currentDate === day.short }">
            <p>{{ day.day }}</p>
            <div>
              <p>{{ day.time && day.time.length > 0 ? day.time : 'CLOSED' }}</p>
            </div>
          </div>
        </div>
        <div v-if="restaurantData.from_table_type === 1" class="hours">
          <h3>From Table Hours</h3>
          <div v-for="day in fromtableHours" :key="day.day" class="line" :class="{ bold: currentDate === day.short }">
            <p>{{ day.day }}</p>
            <div>
              <p>{{ day.time && day.time.length > 0 ? day.time : 'CLOSED' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import 'moment-timezone';
import router from "@/router";
import vClickOutside from "click-outside-vue3"
import mapStyle from '../assets/mapStyle.json'


export default {
  name: 'InfoModal',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      restaurantData: null,
      currentDate: null,
      center: null,
      icon: {
        url: 'https://cdn.mydelish.menu/markers/pelicana-Marker.svg',
        scaledSize: { width: 37, height: 58 }
      },
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: mapStyle   
      },
      pickupHours: null,
      deliveryHours: null,
      curbsideHours: null,
      fromtableHours: null,
      weekDays: {
        Mon: { day: 'Monday', short: 'Mon' },
        Tue: { day: 'Tuesday', short: 'Tue' },
        Wed: { day: 'Wednesday', short: 'Wed' },
        Thu: { day: 'Thursday', short: 'Thu' },
        Fri: { day: 'Friday', short: 'Fri' },
        Sat: { day: 'Saturday', short: 'Sat' },
        Sun: { day: 'Sunday', short: 'Sun' },
      },
    }
  },
  computed: {
    orderTypesList() {
      let orderTypes = [];
      if (this.restaurantData.delivery_type === 1) {
        orderTypes.push("Delivery");
      }
      if (this.restaurantData.pickup_type === 1) {
        orderTypes.push("Pickup");
      }
      if (this.restaurantData.curbside_type === 1) {
        orderTypes.push("Curbside");
      }
      if (this.restaurantData.from_table_type === 1) {
        orderTypes.push("From Table");
      }
      return orderTypes.join(", ");
    },
    restaurantAddress() {
      return  this.restaurantData.address + ", " + 
              this.restaurantData.city + ", " + 
              this.restaurantData.state + " " + 
              this.restaurantData.zipcode;
    },
  },
  methods: {
    getLocationDetails() {
      axios.get(`${process.env.VUE_APP_API_V1_BASE_URL}/restaurants`, {
        params: {
          restaurant_code: this.$store.state.selectedLocation,
          menu_type: 0,
          "filter[is_deleted]": 0,
          "filter[status]": 1,
        },
        headers: {
          apikey: process.env.VUE_APP_API_KEY2,
        },
      })
      .then((response) => {
        if (response.data.meta.status === 200) {
          this.restaurantData = response.data.resource.data[0]
          this.center = { lat: parseFloat(response.data.resource.data[0].lat), lng: parseFloat(response.data.resource.data[0].lng) }
          this.getCurrentDate();
          this.getHoursData();
        }
      })
      .catch((error) => {
        this.errorMessage = error.response.data.resource.errorMessage;
      });
    },
    getCurrentDate() {
      this.currentDate = moment().tz(this.restaurantData.timezoneString).format("ddd");
    },
    getHoursData() {
      if (this.restaurantData.pickup_type === 1) {
        this.isPickupAvailable = true;
        let days = this.restaurantData.pickup_hours.days;
        let from = this.restaurantData.pickup_hours.from;
        let to = this.restaurantData.pickup_hours.to;

        let weekDays = JSON.parse(JSON.stringify(this.weekDays));
        Object.keys(days).forEach((dayKey) => {
          let dayArr = days[dayKey];
          let fromArr = from[dayKey];
          let toArr = to[dayKey];
          dayArr.map((day) => {
            weekDays[day]["time"] = weekDays[day]["time"]
              ? `${weekDays[day]["time"]} / ${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`
              : `${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`;
          });
        });
        this.pickupHours = weekDays;
      }

      if (this.restaurantData.delivery_type === 1) {
        this.isDeliveryAvailable = true;
        let days = this.restaurantData.delivery_hours.days;
        let from = this.restaurantData.delivery_hours.from;
        let to = this.restaurantData.delivery_hours.to;

        let weekDays = JSON.parse(JSON.stringify(this.weekDays));
        Object.keys(days).forEach((dayKey) => {
          let dayArr = days[dayKey];
          let fromArr = from[dayKey];
          let toArr = to[dayKey];
          dayArr.map((day) => {
            weekDays[day]["time"] = weekDays[day]["time"]
              ? `${weekDays[day]["time"]} / ${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`
              : `${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`;
          });
        });
        this.deliveryHours = weekDays;
      }

      if (this.restaurantData.curbside_type === 1) {
        this.isCurbsideAvailable = true;
        let days = this.restaurantData.curbside_hours.days;
        let from = this.restaurantData.curbside_hours.from;
        let to = this.restaurantData.curbside_hours.to;

        let weekDays = JSON.parse(JSON.stringify(this.weekDays));
        Object.keys(days).forEach((dayKey) => {
          let dayArr = days[dayKey];
          let fromArr = from[dayKey];
          let toArr = to[dayKey];
          dayArr.map((day) => {
            weekDays[day]["time"] = weekDays[day]["time"]
              ? `${weekDays[day]["time"]} / ${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`
              : `${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`;
          });
        });
        this.curbsideHours = weekDays;
      }

      if (this.restaurantData.from_table_type === 1) {
        this.isFromTableAvailable = true;
        let days = this.restaurantData.from_table_hours.days;
        let from = this.restaurantData.from_table_hours.from;
        let to = this.restaurantData.from_table_hours.to;

        let weekDays = JSON.parse(JSON.stringify(this.weekDays));
        Object.keys(days).forEach((dayKey) => {
          let dayArr = days[dayKey];
          let fromArr = from[dayKey];
          let toArr = to[dayKey];
          dayArr.map((day) => {
            weekDays[day]["time"] = weekDays[day]["time"]
              ? `${weekDays[day]["time"]} / ${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`
              : `${fromArr[0]} - ${
                  toArr[0] === "12:00 AM" ? "11:45 PM" : toArr[0]
                }`;
          });
        });
        this.fromtableHours = weekDays;
      }
    },
    closeInfoModal() {
      this.$emit('closeInfoModal');
    },
    openLocation(code) {
      router.push({ name: 'Location', params: { code: code } })
    },
  },
  created() {
    this.getLocationDetails();
  },
}
</script>

<style scoped>
.modalContainer {
  background-color: rgba(48, 48, 48, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 20px;
  z-index: 999;
}
.modal {
  display: flex;
  flex-direction: column;
  width: 600px;
  background-color: var(--white);
  border-radius: 10px;
  max-height: 600px;
  overflow: auto;
  position: relative;
}
.hours:last-child {
  border: none;
}
.info, .orderTypes, .hours {
  padding: 30px 0px;
  margin: 0px 30px;
  border-bottom: 0.5px solid var(--border);
}
.info h1 {
  margin-bottom: 10px;
}
.info p:last-child {
  margin-top: 5px;
}
.orderTypes h3 {
  margin-bottom: 10px;
}
.line {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.line p {
  text-align: right;
}
.hours h3 {
  margin-bottom: 5px;
}
.bold {
  font-weight: 700;
}
.mapPart {
  height: 200px;
}
.map {
  position: relative;
}
.content {
  overflow: auto;
  padding-bottom: 100px;
}
.close {
  height: 40px;
  width: 40px;
  display: flex;
  background-color: var(--white);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 10px;
  border-radius: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
}
.button {
  width: 100%;
  padding: 20px;
  border-top: 0.5px solid var(--border);
  position: absolute;
  bottom: 0;
  background-color: var(--white);
}
.button * {
  width: 100%;
}
</style>
