import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedLocation: null,
    openedMarkerIndex: null,
    selectedOrderType: localStorage.getItem('selectedOrderType') ? localStorage.getItem('selectedOrderType') : null,
  },
  mutations: {
    CHANGE_SELECTED_LOCATION(state, location) {
      state.selectedLocation = location
    },
    CHANGE_SELECTED_ORDER_TYPE(state, orderType) {
      state.selectedOrderType = orderType
    }
  },
  actions: {
  },
  modules: {
  }
})
