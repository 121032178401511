<template>
  <div class="app">
    <div class="topBar">
      <a @click="$router.push({ name: 'Home' })" v-if="$route.name == 'Location'" class="textButton" href="#">
        <img src="./assets/back.svg" alt="">
        <p>Back</p>
      </a>
      <a href="https://www.pelicanausa.com/">
        <img class="logo" src="./assets/logo.png" alt="">
      </a>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive include="Home">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>

const link = document.createElement("link");
link.href = 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,900&display=swap';
link.rel = "stylesheet";
document.head.appendChild(link);

export default {
  name: "App",
  data() {
    return {
    };
  },
  methods: {
    calculateInnerHeight() {
      const innerHeight = window.innerHeight;
      document.documentElement.style.setProperty("--innerHeight", `${innerHeight}px`);
    },
  },
  created() {
    this.calculateInnerHeight();

    window.addEventListener('resize', this.calculateInnerHeight);
    window.addEventListener('orientationchange', this.calculateInnerHeight);
  }
};

</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--secondary);
}
p {
  color: var(--secondary);
  font-size: 13px;
}
label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--secondary);
}
input {
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--border);
  outline: none;
  padding: 10px;
  color: var(--secondary);
}
.app {
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  height: var(--innerHeight);
}
button {
  height: 55px;
  border-radius: 7px;
  color: var(--white);
  background-color: var(--primary);
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
}
button:hover {
  background-color: var(--primaryHover);
}
.topBar {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px 5px 20px;
  position: relative;
  height: 70px;
}
.textButton {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  left: 20px;
  top: 24px;
}
.textButton p {
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
}
a {
  text-decoration: none;
}
.logo {
  width: 100px;
}

:root {
  --primary: #c94249ff;
  --primaryHover: #A4393E;
  --secondary: #49494a;
  --secondaryHover: #373737;
  --background: #eeebeb;
  --white: #ffffff;
  --shadow: #acacac85;
  --border: #c5c5c5;
  --hoverBg: #f3f3f3;
  --passive: #acacac;
  --passiveHover: #747474;
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 600px) {
    input {
        -webkit-appearance: none;
        font-size: 16px;
    }
}
</style>
