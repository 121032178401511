<template>
  <div class="home">
    <InfoModal v-if="infoModalIsOpen" @closeInfoModal="infoModalIsOpen = !infoModalIsOpen" />
    <div class="list">
      <div class="poweredBy">
        <a href="https://www.lunchbox.io" target="_blank">
          <img src="../assets/poweredBy.svg" alt="">
        </a>
      </div>
      <div class="topPart">
        <h2 v-if="locations.order_types && locations.order_types.length > 0">Select an Order Type</h2>
        <div v-if="locations.order_types && locations.order_types.length == 1" class="orderType2">
          <div class="orderType orderTypeSingle selected">
            <img :src="availableOrderTypes[0].iconPath" alt="">
            <h3>{{ availableOrderTypes[0].displayName }} ONLY</h3>
          </div>
        </div>
        <div v-if="locations.order_types && locations.order_types.length == 2" class="orderType2">
          <div @click="orderTypeChanged(0)" class="orderType orderTypeLeft" :class="{ selected: selectedOrderType === availableOrderTypes[0].order_type_code }">
            <img :src="availableOrderTypes[0].iconPath" alt="">
            <h3>{{ availableOrderTypes[0].displayName }}</h3>
          </div>
          <div @click="orderTypeChanged(1)" class="orderType orderTypeRight" :class="{ selected: selectedOrderType === availableOrderTypes[1].order_type_code }">
            <img :src="availableOrderTypes[1].iconPath" alt="">
            <h3>{{ availableOrderTypes[1].displayName }}</h3>
          </div>
        </div>
        <div v-if="locations.order_types && locations.order_types.length > 2" class="orderTypes" v-click-outside="closeDropDown" >
          <div @click="toggleOrderTypeDropdown" class="types">
            <img :src="availableOrderTypes[this.selectedOrderTypeIndex].iconPath" alt="">
            <h3>{{ availableOrderTypes[this.selectedOrderTypeIndex].displayName }}</h3>
            <img class="arrow" :class="{ reverse : orderTypeDropdownIsOpen }" src="../assets/down.svg" alt="">
          </div>
          <div v-if="orderTypeDropdownIsOpen" class="dropdown">
            <div @click="orderTypeChanged(index)" v-for="(type, index) in availableOrderTypes" :key="type" class="orderType">
              <img :src="type.iconPath" alt="">
              <h3>{{ type.displayName }}</h3>
            </div>
          </div>
        </div>
        <label for="address">Your Address</label>
        <div class="inputWrapper">
          <div v-if="selectedAddressLat && selectedAddressLat" class="remove">
            <a @click="addressValue('')" href="#">
              <img src="../assets/close.svg" alt="">
            </a>
          </div>
          <GMapAutocomplete
            id="address"
            placeholder="Enter Your Address"
            @input="addressValue($event.target.value)"
            @place_changed="getLatAndLng"
            :options="{
              componentRestrictions: { country: 'us' }
            }"
          >
          </GMapAutocomplete>    
        </div>
      </div>
      <div v-if="selectedOrderType === 0 && (!selectedAddressLat && !selectedAddressLat)" class="noLocation">
        <img src="../assets/logoColor.png" alt="">
        <h3>Please enter your address to see the locations that deliver to you.</h3>
      </div>
      <div v-else-if="locations.restaurants && locations.restaurants.length === 0">
        <div v-if="!loading" class="noLocation">
          <img src="../assets/logoColor.png" alt="">
          <h3>There are no locations that deliver to your address.</h3>
        </div>
      </div>
      <div v-else class="listPart">
        <div v-if="loading" class="loader">
        </div>
        <div v-if="!loading">
          <div v-for="(location, index) in locations.restaurants" :key="location" class="location" @click="openMarker(index)">
            <div class="info">
              <h2>{{ location.restaurant_name }}</h2>
              <p>{{ location.address }}</p>
              <p>{{ formatPhoneNumbers(location.phone) }}</p>
              <a href="#" @click="getRestaurantCode(location.restaurant_code)">Hours & Info</a>
            </div>
            <div class="right">
              <button @click="openLocation(location.restaurant_code)">ORDER</button>
              <p class="distance" v-if="selectedAddressLat && selectedAddressLng" >{{ getDistance(selectedAddressLat, selectedAddressLng, location.lat, location.lng) }} miles away</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <GMapMap
        :center="center"
        :zoom="zoom"
        panTo="true"
        :options="options"
        @zoom_changed="zoomChanged($event)"
        map-type-id="terrain"
        class="mapPart"
      >
        <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :icon="icon"
          :clickable="true"
          @click="openMarker(index); center=m.position"
          :zIndex="999999"
        >
          <GMapInfoWindow
            :closeclick="true"
            :opened="openedMarkerIndex === index"
          >
            <div class="mapInfo">
              <h3>{{ locations.restaurants[index].restaurant_name }}</h3>
              <p>{{ locations.restaurants[index].address }}</p>
              <p>{{ locations.restaurants[index].phone }}</p>
              <a href="#" @click="getRestaurantCode(locations.restaurants[index].restaurant_code)">Hours & Info</a>
              <button @click="openLocation(locations.restaurants[index].restaurant_code)">ORDER</button>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
        <GMapMarker
          :position="{ lat: selectedAddressLat, lng: selectedAddressLng }"
          :icon="userIcon"
          :clickable="false"
          v-if="selectedAddressLat && selectedAddressLng"
        >
        </GMapMarker>
      </GMapMap>
    </div>
  </div>
</template>

<script>
import mapStyle from '../assets/mapStyle.json'
import InfoModal from '../components/InfoModal.vue'
import vClickOutside from "click-outside-vue3"

import { mapMutations } from "vuex"
import router from "@/router";
import axios from "axios";

export default {
  name: 'Home',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    InfoModal
  },
  data() {
    return {
      infoModalIsOpen: false,
      selectedOrderType: null,
      selectedOrderTypeIndex: 0,
      addressInputText: "",
      availableOrderTypes: [],
      orderTypeDropdownIsOpen: false,
      selectedAddressLat: null,
      selectedAddressLng: null,
      loading: false,
      center: { lat: 40.481528440974174, lng: -74.51486790790624 },
      zoom: 9,
      openedMarkerIndex: null,
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: mapStyle   
      },
      icon: {
        url: 'https://cdn.mydelish.menu/markers/pelicana-Marker.svg',
        scaledSize: { width: 37, height: 58 }
      },
      userIcon: {
        url: 'https://cdn.mydelish.menu/markers/user-marker.png',
        scaledSize: { width: 35, height: 45 }
      },
      markers: [],
      locations: {}
    }
  },
  watch: {
    $route() {
      this.openedMarkerIndex = null
    }
  },
  methods: {
    ...mapMutations([
      "CHANGE_SELECTED_LOCATION",
      "CHANGE_SELECTED_ORDER_TYPE",
    ]),
    getMarkerLocations() {
      this.markers = [];      
      for (let i = 0; i < this.locations.restaurants.length; i++) {
        this.markers.push({
          position: {
            lat: parseFloat(this.locations.restaurants[i].lat),
            lng: parseFloat(this.locations.restaurants[i].lng)
          }
        })
      }
    },
    formatPhoneNumbers(phone) {
      let cleaned = ('' + phone).replace(/\D/g, '')
      cleaned = cleaned.substring(0, 10)
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return
    },
    getDistance(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      d = d * 0.621371; // convert d to miles
      return d.toFixed(2);
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    addressValue(value) {
      if (value === '') {
        document.getElementById('address').value = '';
        this.selectedAddressLat = null;
        this.selectedAddressLng = null;
        this.getLocations();
      }
    },
    toggleInfoModal() {
      this.infoModalIsOpen = !this.infoModalIsOpen;
    },
    getLatAndLng(addressData) {
      this.selectedAddressLat = addressData.geometry.location.lat();
      this.selectedAddressLng = addressData.geometry.location.lng();
      this.center = { lat: this.selectedAddressLat, lng: this.selectedAddressLng };
      this.zoom = 13;
      this.getLocations();
    },
    openMarker(index) {
      if (this.openedMarkerIndex === index) {
        this.openedMarkerIndex = null;
        setTimeout(() => {
          this.openedMarkerIndex = index;
          this.center = { lat: this.markers[index].position.lat, lng: this.markers[index].position.lng };
          this.zoom = 15;
        }, 100);
      } else {
        this.openedMarkerIndex = index;
        this.center = { lat: this.markers[index].position.lat, lng: this.markers[index].position.lng };
        this.zoom = 15;
      }
    },
    zoomChanged(zoom) {
      this.zoom = zoom
    },
    openLocation(code) {
      this.CHANGE_SELECTED_LOCATION(code)
      router.push({ name: 'Location', params: { code: code } })
    },
    getRestaurantCode(code) {
      this.CHANGE_SELECTED_LOCATION(code)
      this.toggleInfoModal()
    },
    toggleOrderTypeDropdown() {
      this.orderTypeDropdownIsOpen = !this.orderTypeDropdownIsOpen;
    },
    closeDropDown() {
      this.orderTypeDropdownIsOpen = false;
    },
    orderTypeChanged(index) {
      this.selectedOrderType = this.availableOrderTypes[index].order_type_code;
      this.CHANGE_SELECTED_ORDER_TYPE(this.availableOrderTypes[index].order_type_code);
      localStorage.setItem('selectedOrderType', this.availableOrderTypes[index].order_type_code);
      this.getLocations();
      this.orderTypeDropdownIsOpen = false
      this.selectedOrderTypeIndex = index
    },
    getAvailableOrderTypes() {
      axios.get(`${process.env.VUE_APP_API_V3_BASE_URL}/restaurant/landing-page`, {
        params: {
          landing_page_code: 2130678631,
        },
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          for (let i = 0; i < response.data.locations.order_types.length; i++) {
            let order_type_code
            let displayName
            switch (response.data.locations.order_types[i]) {
              case "delivery":
                order_type_code = 0;
                displayName = "DELIVERY";
                break;
              case "pickup":
                order_type_code = 1;
                displayName = "PICKUP";
                break;
              case "curbside":
                order_type_code = 2;
                displayName = "CURBSIDE";
                break;
              case "fromtable":
                order_type_code = 3;
                displayName = "FROM TABLE";
                break;
            }
            this.availableOrderTypes.push({
              name: response.data.locations.order_types[i],
              order_type_code: order_type_code,
              displayName: displayName,
              iconPath: require("../assets/" + response.data.locations.order_types[i] + ".svg"), 
            })
          }
          this.selectedOrderType = this.availableOrderTypes[0].order_type_code
          this.CHANGE_SELECTED_ORDER_TYPE(this.availableOrderTypes[0].order_type_code);
          localStorage.setItem('selectedOrderType', this.availableOrderTypes[0].order_type_code)
          this.getLocations();
        }
      })
      .catch((error) => {
        this.errorMessage = error;
      });
    },
    getLocations() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_V3_BASE_URL}/restaurant/landing-page`, {
        params: {
          landing_page_code: 2130678631,
          lat: this.selectedAddressLat,
          lng: this.selectedAddressLng,
          order_type: this.selectedOrderType,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.locations = response.data.locations
          for (let i = 0; i < this.locations.restaurants.length; i++) {
            this.locations.restaurants[i].distance = this.getDistance(this.selectedAddressLat, this.selectedAddressLng, this.locations.restaurants[i].lat, this.locations.restaurants[i].lng)
          }
          this.locations.restaurants.sort((a, b) => {
            return a.distance - b.distance
          })
          this.getMarkerLocations()

          this.availableOrderTypes = []
          for (let i = 0; i < response.data.locations.order_types.length; i++) {
            let order_type_code
            let displayName
            switch (response.data.locations.order_types[i]) {
              case "delivery":
                order_type_code = 0;
                displayName = "DELIVERY";
                break;
              case "pickup":
                order_type_code = 1;
                displayName = "PICKUP";
                break;
              case "curbside":
                order_type_code = 2;
                displayName = "CURBSIDE";
                break;
              case "fromtable":
                order_type_code = 3;
                displayName = "FROM TABLE";
                break;
            }
            this.availableOrderTypes.push({
              name: response.data.locations.order_types[i],
              order_type_code: order_type_code,
              displayName: displayName,
              iconPath: require("../assets/" + response.data.locations.order_types[i] + ".svg"), 
            })
          }
          this.loading = false;
        }
      })
      .catch((error) => {
        this.errorMessage = error;
      });
    }
  },
  created() {
    this.getAvailableOrderTypes();
  }
}
</script>

<style scoped>
.home {
  display: grid;
  grid-template-columns: 500px 1fr;
  height: calc(var(--innerHeight) - 70px);
  overflow: hidden;
}
.map {
  background-color: var(--white);
  outline: none;
}
.mapPart {
  height: 100%;
}
.orderType2 {
  height: 45px;
  border-radius: 7px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.topPart {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-bottom: 1px solid var(--border);
  background-color: var(--background);
}
.list {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}
.listPart {
  overflow: auto;
  padding-bottom: 30px;
}
.orderType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1.5px solid var(--border);
  cursor: pointer;
}
.orderType img, .orderType h3 {
  opacity: 0.6;
}
.orderTypeLeft {
  border-radius: 7px 0px 0px 7px;
}
.orderTypeRight {
  border-radius: 0px 7px 7px 0px;
}
.orderTypeSingle {
  border-radius: 7px;
}
.types {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 45px;
  border: 1.5px solid var(--border);
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
}
.types img:first-child {
  margin-right: 5px;
}
.types img:last-child {
  margin-left: auto;
}
.orderType img {
  margin-right: 5px;
}
.location {
  display: flex;
  gap: 10px;
  padding: 20px;
  align-items: stretch;
  background-color: var(--white);
  margin-bottom: 10px;
  cursor: pointer;
}
.location button {
  width: 120px;
}
.info {
  flex: 1;
}
.info h2 {
  margin-bottom: 10px;
}
.info p {
  margin-bottom: 5px;
}
.info p:nth-child(3) {
  margin-bottom: 10px;
}
.info a, .mapInfo a {
  color: var(--primary);
  font-weight: 700;
  font-size: 13px;
  outline: none;
}
.selected {
  background-color: var(--primary);
  border: none;
}
.selected h3 {
  color: var(--white);
  opacity: 1;
}
.selected img {
  filter: brightness(0) invert(1);
  opacity: 1;
}
.poweredBy {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  width: 100%;
}
.poweredBy a img {
  width: 140px;
}
.mapInfo h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.mapInfo p {
  font-size: 14px;
  font-weight: 400;
}
.mapInfo p:nth-child(2) {
  margin-bottom: 5px;
}
.mapInfo p:nth-child(3) {
  margin-bottom: 15px;
}
.mapInfo button {
  width: 100%;
  margin-top: 20px;
}
.dropdown {
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 60px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.dropdown .orderType {
  border: none;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.dropdown .orderType:last-child {
  margin-bottom: 0;
}
.orderTypes {
  position: relative;
}
.distance {
  margin-top: auto;
  font-size: 12px;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.arrow {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}
.reverse {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
.noLocation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.noLocation img {
  width: 100px;
  margin-bottom: 10px;
  margin-top: 50px;
}
.noLocation h3 {
  color: var(--primary);
  font-weight: 700;
  text-align: center;
  max-width: 300px;
}
.remove {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 10px;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 5px;
}
.remove a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove a img {
  height: 14px;
  width: 14px;
}
.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.loader {
  content: "";
  width: 32px;
  height: 32px;
  margin: auto;
  margin-top: 100px;
  border: 4px solid transparent;
  border-top-color: var(--passive);
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;
}
@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 1200px) {
  .home {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 800px) {
  .home {
    grid-template-columns: 1fr;
  }
  .map {
    display: none;
  }
}
</style>
