<template>
  <div class="location">
    <iframe :src="url" frameborder="0" width="100%"></iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Location',
  data() {
    return {
      url: `${process.env.VUE_APP_IFRAME_BASE_URL}/` + this.$route.params.code
    }
  },
  components: {
    
  },
  computed: {
    ...mapState([
      'selectedOrderType'
    ])
  },
  created() {
    this.url = this.url + `?orderType=${this.selectedOrderType}`
  }
}
</script>

<style scoped>
iframe {
  display: block;
  background: var(--background);
  border: none; 
  height: calc(var(--innerHeight) - 70px);
}

@media screen and (max-width: 600px) {
  iframe {
    height: calc(var(--innerHeight) - 70px);
  }
}
</style>
