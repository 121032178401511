import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App).use(store).use(router).use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjj9t9ENAHAYsbR67B_GUAki16VtG5Aqo',
    libraries: "places"
  }
}).mount('#app')
